<template>
	<div class="serviceitems">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_service_banner.png">
            <div>
                <div class="container">
                    <h2>OUR SERVICE</h2> 
                    <p>竭诚为您提供一体化服务支持</p>
                </div>
            </div>
        </div>
        <!-- 管理团队 -->
        <div class="container newsList">
            <h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					服务项目
				</span>
			</h2>
            <p class="news">OUR SERVICE</p>
            <p class="policy">我们紧随国家“一带一路“政策”，与班列及运营公司紧密合作，以现代物流信息系统为基础，以专业服务为核心，致力于打造专业成都物流平台。</p>
            <div class="serviceDetail" @click="jumpUrl(1)">
                <img src="@/assets/img_service_1.png" class="rightImg">
                <div class="serviceContent serviceLeft">
                    <div class="agentLeft agentLeft1">
                        <div>国际货代</div>
                        <p>作为第一批蓉欧班列的订舱货运代理，我们立志成为综合运输和物流服务的领军者之一。</p>
                    </div>
                    <div class="agentRight agentRight1">
                        <div><span class="icon font_family icon-zhengque1"></span>整柜订舱</div>
                        <div><span class="icon font_family icon-zhengque1"></span>散柜拼箱</div>
                        <div><span class="icon font_family icon-zhengque1"></span>跨境中转</div>
                        <div><span class="icon font_family icon-zhengque1"></span>国际运输</div>
                        <div><span class="icon font_family icon-zhengque1"></span>方案定制</div>
                    </div>
                </div>
            </div>
            <div class="serviceDetail" @click="jumpUrl(2)">
                <img src="@/assets/img_service_2.png" class="leftImg">
                <div class="serviceContent serviceRight">
                    <div class="agentLeft agentLeft2 agentLeft3">
                        <div>保税仓储</div>
                        <p>秉承以客为尊、精益求精及不断创新的服务精神。着力打造以西南地区为基地并面向全球的仓储服务，为各类型客户提供最专业，最完善的供应链服务。</p>
                    </div>
                    <div class="agentRight agentRight2">
                        <div><span class="icon font_family icon-zhengque1"></span>整柜、散柜装箱/拆箱</div>
                        <div><span class="icon font_family icon-zhengque1"></span>货物掏箱、分拣/包装</div>
                        <div><span class="icon font_family icon-zhengque1"></span>方案制定</div>
                        <div><span class="icon font_family icon-zhengque1"></span>分类整合</div>
                        <div><span class="icon font_family icon-zhengque1"></span>质量检测</div>
                        <div><span class="icon font_family icon-zhengque1"></span>贴标换标</div>
                    </div>
                    <div class="agentLeft agentLeft2">
                        <div>保税仓储</div>
                        <p>秉承以客为尊、精益求精及不断创新的服务精神。着力打造以西南地区为基地并面向全球的仓储服务，为各类型客户提供最专业，最完善的供应链服务。</p>
                    </div>
                </div>
            </div>
            <div class="serviceDetail" @click="jumpUrl(3)">
                <img src="@/assets/img_service_3.png" class="rightImg">
                <div class="serviceContent serviceLeft">
                    <div class="agentLeft agentLeft1">
                        <div>进出口报关</div>
                        <p>中华人民共和国海关总署正式批准的专业报关企业，可在中国境内各主要口岸提供优质服务的专业报关行。坚持以客户为中心，快速响应客户需求，持续为客户创造长期价值进而成就客户。</p>
                    </div>
                    <div class="agentRight agentRight1">
                        <div><span class="icon font_family icon-zhengque1"></span>一般贸易</div>
                        <div><span class="icon font_family icon-zhengque1"></span>跨境货物</div>
                        <div><span class="icon font_family icon-zhengque1"></span>私人物品</div>
                        <div><span class="icon font_family icon-zhengque1"></span>区区流转</div>
                        <div><span class="icon font_family icon-zhengque1"></span>进口保税</div>
                        <div><span class="icon font_family icon-zhengque1"></span>进出口清关</div>
                    </div>
                </div>
            </div>
            <div class="serviceDetail" @click="jumpUrl(4)">
                <img src="@/assets/img_service_4.png" class="leftImg">
                <div class="serviceContent serviceRight">
                    <div class="agentLeft agentLeft2 agentLeft3">
                        <div>跨境贸易</div>
                        <p>为海外供应商提供更多国内销售渠道。为国内小Ｂ客户提供更多优质货源及保税代发服务。同时亦是把信息科技及电子商贸应用在全方位货物运输过程。</p>
                    </div>
                    <div class="agentRight agentRight2">
                        <div><span class="icon font_family icon-zhengque1"></span>跨境出口（9610/9710/9810）</div>
                        <div><span class="icon font_family icon-zhengque1"></span>跨境进口</div>
                        <div><span class="icon font_family icon-zhengque1"></span>保税备货</div>
                        <div><span class="icon font_family icon-zhengque1"></span>保税代发</div>
                        <div><span class="icon font_family icon-zhengque1"></span>代收代付</div>
                        <div><span class="icon font_family icon-zhengque1"></span>税费办理</div>
                        <div><span class="icon font_family icon-zhengque1"></span>供应链金融</div>
                    </div>
                    <div class="agentLeft agentLeft2">
                        <div>跨境贸易</div>
                        <p>为海外供应商提供更多国内销售渠道。为国内小Ｂ客户提供更多优质货源及保税代发服务。同时亦是把信息科技及电子商贸应用在全方位货物运输过程。</p>
                    </div>
                </div>
            </div>
            <div class="serviceDetail" @click="jumpUrl(5)">
                <img src="@/assets/img_service_5.png" class="rightImg">
                <div class="serviceContent serviceLeft">
                    <div class="agentLeft agentLeft1">
                        <div>贸易代理</div>
                        <p>根据您的需求我们会您提供更多的优质货源或者国内销售渠道，供应链金融服务，税费办理。</p>
                    </div>
                    <div class="agentRight agentRight1">
                        <div><span class="icon font_family icon-zhengque1"></span>财税顾问</div>
                        <div><span class="icon font_family icon-zhengque1"></span>投资顾问</div>
                        <div><span class="icon font_family icon-zhengque1"></span>财税代理</div>
                        <div><span class="icon font_family icon-zhengque1"></span>商务资讯</div>
                    </div>
                </div>
            </div>
            <div class="serviceDetail" @click="jumpUrl(6)">
                <img src="@/assets/img_service_6.png" class="leftImg">
                <div class="serviceContent serviceRight">
                    <div class="agentLeft agentLeft2  agentLeft3">
                        <div>文创中心</div>
                        <p>我们为客户提供从创新研究、用户调研、产品设计到品牌建构的全面商业解决方案。</p>
                    </div>
                    <div class="agentRight agentRight2">
                        <div><span class="icon font_family icon-zhengque1"></span>文创衍生品开发</div>
                        <div><span class="icon font_family icon-zhengque1"></span>IP形象设计</div>
                        <div><span class="icon font_family icon-zhengque1"></span>文化创意策划</div>
                        <div><span class="icon font_family icon-zhengque1"></span>工艺美术品</div>
                        <div><span class="icon font_family icon-zhengque1"></span>组织文化艺术交流活动</div>
                        <div><span class="icon font_family icon-zhengque1"></span>企业形象策划</div>
                    </div>
                    <div class="agentLeft agentLeft2">
                        <div>文创中心</div>
                        <p>我们为客户提供从创新研究、用户调研、产品设计到品牌建构的全面商业解决方案。</p>
                    </div>
                </div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "serviceitems",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
        jumpUrl(num) {
            switch (num) {
                case 1:
                    num = 'servicedetail'
                    break;
                case 2:
                    num = 'servicegoods'
                    break;
                case 3:
                    num = 'serviceclearance'
                    break;
                case 4:
                    num = 'servicebonded'
                    break;
                case 5:
                    num = 'servicedeputy'
                    break;
                case 6:
                    num = 'servicepinkoi'
                    break;
                default:
                    num = 'servicedetail'
                    break;
            }
            if(typeof(num) !== 'string'){
                return
            }else{
                this.$router.push(num)
            }
		},
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "serviceitems.css";
</style>
